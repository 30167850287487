import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import {
  Box,
  Grid,
  IconButton
} from '@mui/material';
import clsx from 'clsx';
import { Product } from '@pizza-hut-us-development/client-core';
import Badge, { SoldOutBadge } from '@/coreComponents/boxes/Badge';
import AnchorLink, { AnchorLinkProps } from '@/common/AnchorLink/AnchorLink';
import { LinkTypes } from '@/common/AnchorLink/types';
import InfoIcon from '@/localization/icons/InfoIcon';
import InfoRail from '@/common/components/InfoRail';
import InfoRailStyles from '@/common/components/InfoRail/InfoRail.styles';
import ProductImage from '@/common/components/LocalizedProductTile/ProductImage';
import Routes from '@/router/routes';
import { CtaButton } from '@/coreComponents/buttons/CtaButton/CtaButton';
import useShowNewBadge from '@/menu/hooks/useShowNewBadge';
import NewBadge from '@/coreComponents/boxes/Badge/NewBadge';
import ProductId from '@/common/ProductId';
import { useMenuTileStyles } from './MenuTile.styles';

import oldMenuTileStyles from '../../../menu/MenuTile/MenuTile.styles';
import { AddToCart } from '../../organisms/AddToCart';
import ProductDetails from '@/common/ProductDetails/ProductDetails';
import { getNutritionInfo } from '@/clientCore/menu/utils/pizzaNutrition';
import useSodiumAlertForStore from '@/clientCore/components/SodiumWarningDisclaimer/hooks/useSodiumAlertForStore';
import { SodiumWarningIcon } from '@/clientCore/components/SodiumWarningDisclaimer/components/SodiumWarningIcon';
import { checkSodiumWarning } from '@/clientCore/helper/checkSodiumWarning';

import css from './MenuTile.module.css';

export const MENU_TILE_TESTID = 'pizza-menu-tile';
export const NUTRITION_INFO_TESTID = 'nutrition-info';
export const TILE_NAME_TESTID = 'menu-tile-title';
export const CUSTOMIZE_BUTTON_TESTID = 'customize-button';
export const PRODUCT_INFO_RAIL_TESTID = 'product-info-rail';
export const PRODUCT_INFO_ICON_TESTID = 'product-info-icon';

const hasValidProductDefaults = (product: Product) => (product.selectedOptions ?? []).length > 0;

type ProductLinkProps = Partial<AnchorLinkProps> & {
  link: string;
};

const ProductLink = ({ children, link, ...linkProps }: ProductLinkProps) => (
  <AnchorLink
    {...linkProps}
    link={link}
    linkType={LinkTypes.RELATIVE_LINK}
    external={false}
  >
    {children}
  </AnchorLink>
);

interface MenuTileProps {
  product: Product;
  handleClick(): void;
  isCyo?: boolean;
  isNationalStore?: boolean;
  badgeOptions?: BadgeOptions;
  handleAnalyticsAddToCartClick?(quantity: number): void;
  handleAnalyticsCustomizeLinkClick?(): void;
  handleAnalyticsAddToCartSuccess?(quantity: number): void;
  handleAnalyticsGetStartedClick?(): void;
}

const MenuTile = (props: MenuTileProps): JSX.Element => {
  const {
    handleClick,
    product,
    isCyo,
    isNationalStore = false,
    badgeOptions,
    handleAnalyticsAddToCartClick,
    handleAnalyticsCustomizeLinkClick,
    handleAnalyticsAddToCartSuccess,
    handleAnalyticsGetStartedClick
  } = props;
  const {
    id,
    name,
    imageURL,
    price,
    description,
    outOfStock,
    selectedOptions
  } = product;
  const { globalId: productId } = new ProductId(id ?? '');
  const classes = oldMenuTileStyles({ outOfStock, hasLinkEnabled: !outOfStock });
  const menuTileClasses = useMenuTileStyles();
  const { isShowSodiumAlertForStore } = useSodiumAlertForStore();
  const showSodiumWarning = isShowSodiumAlertForStore && (product?.sodiumWarning || checkSodiumWarning(selectedOptions));
  const nutritionInfo = getNutritionInfo(selectedOptions);
  const shouldShowNutritionInfo = !isCyo && !!nutritionInfo;
  const classesInfoRail = InfoRailStyles();
  const [showInfoRail, setShowInfoRail] = useState<boolean>(false);
  const customizeLinkUrl = `${Routes.MENU.PIZZA_BUILDER}?id=${productId}`;
  const showGetStartedButton = isNationalStore || isCyo || !hasValidProductDefaults(product);
  const { shouldShowNewBadge } = useShowNewBadge(productId);
  const badges = {
    new: shouldShowNewBadge,
    soldOut: outOfStock,
    popular: false, // TODO: Update once CC provide the correct data
    vegetarian: false // todo: update when CC provides this data
  };

  let badgeComponent;
  if (badges.soldOut) {
    badgeComponent = <SoldOutBadge />;
  } else if (badges.new) {
    badgeComponent = <NewBadge />;
  } else if (!badgeOptions?.popular?.isHidden && badges.popular) {
    badgeComponent = <Badge alert text="POPULAR" />;
  } else if (badges.vegetarian) {
    badgeComponent = null;
  }

  const handleInfoClick = (e?: React.SyntheticEvent) => {
    e?.preventDefault();
    e?.stopPropagation();

    setShowInfoRail(true);
  };

  const onHandleCTAClick = () => {
    handleClick();
    handleAnalyticsGetStartedClick?.();
  };

  const showInfoButton = !!description && !outOfStock;
  const infoRail = showInfoButton ? (
    <InfoRail id={PRODUCT_INFO_RAIL_TESTID} visible={showInfoRail} onClose={() => setShowInfoRail(false)}>
      <Box p={2}>
        <Typography component="p" className={classesInfoRail.subtitle}>
          {name}
        </Typography>
        <Typography className={classesInfoRail.description}>
          {description}
        </Typography>
      </Box>
    </InfoRail>
  ) : null;

  return (
    <Grid item className={css.card} data-testid={MENU_TILE_TESTID}>
      <div className={css.card__content}>
        <div className={css['menu-tile__content']}>
          <ProductLink linkTitle={name} link={customizeLinkUrl} className={css['menu-tile__image']}>
            <ProductImage
              imageUrl={imageURL ?? ''}
              description={description ?? ''}
              classes={{
                imageContainer: css['menu-tile__image-container'],
                image: clsx(!outOfStock && css['menu-tile__image'])
              }}
            />
          </ProductLink>
          <div className={css['menu-tile__product-info']}>
            <div className={css['menu-tile__product-name-crust-container']}>
              <div>
                {badgeComponent
                && (
                  <div data-testid="tile-badge" className={css['menu-tile__badge']}>
                    {badgeComponent}
                  </div>
                )}
                <Typography
                  data-testid={TILE_NAME_TESTID}
                  className={clsx(outOfStock && classes.outOfStock, menuTileClasses.title)}
                >
                  {name}
                </Typography>

              </div>
              {showInfoButton && (
              <IconButton
                data-testid={PRODUCT_INFO_ICON_TESTID}
                aria-label="Product Info"
                aria-haspopup="dialog"
                disableFocusRipple
                disableRipple
                className={css['menu-tile__info-icon']}
                onClick={handleInfoClick}
                size="large"
              >
                <InfoIcon />
              </IconButton>
              )}
            </div>
            <div>
              {!!price && (
              <ProductDetails
                price={price}
                product={product}
                isCyo={!!isCyo}
              />
              )}
              {showSodiumWarning && <SodiumWarningIcon width="116" height="14" />}
            </div>
          </div>
          <Grid container className={css['menu-tile__cta']}>
            {!showGetStartedButton && (
            <ProductLink
              linkTitle={name}
              link={customizeLinkUrl}
              className={css['menu-tile__cta-link']}
              testId={CUSTOMIZE_BUTTON_TESTID}
              onClick={handleAnalyticsCustomizeLinkClick}
            >
              <>Customize</>
            </ProductLink>
            )}
            <div className={css['menu-tile__cta-button']}>
              {showGetStartedButton ? (
                <CtaButton
                  color={isCyo ? 'primary' : 'secondary'}
                  labelText="Get Started"
                  size="medium"
                  disabled={outOfStock}
                  ariaHidden
                  clickHandler={onHandleCTAClick}
                />
              ) : (
                <AddToCart
                  product={product}
                  disabled={outOfStock}
                  handleAnalyticsAddToCartClick={handleAnalyticsAddToCartClick}
                  handleAnalyticsAddToCartSuccess={handleAnalyticsAddToCartSuccess}
                />
              )}
            </div>
          </Grid>
        </div>
      </div>
      {shouldShowNutritionInfo && (
      <div
        className={css.card__footer}
        data-testid={NUTRITION_INFO_TESTID}
      >
        {nutritionInfo}
      </div>
      )}
      {infoRail}
    </Grid>
  );
};

export default MenuTile;
